import { FC, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { default as lodash } from 'lodash';
import { IRedirect, redirectActions } from '../../commons/redirectReducer';
import { Layout } from '../rootContext/layout';
import { NavigationComponent } from '../../components/navigation.component';
import { UiModeComponent } from '../../components/uiMode.component';
import { SignalRStateComponent } from '../../components/signalR/signalRState';
import AppBar from "../../components/muiComponents/core/AppBar";
import Grid from "../../components/muiComponents/core/Grid";
import Tabs from "../../components/muiComponents/core/Tabs";
import Tab from "../../components/muiComponents/core/Tab";
import IconButton from "../../components/muiComponents/core/IconButton";
import { FullscreenExitOutlined, FullscreenOutlined } from "@mui/icons-material";
import Container from "../../components/muiComponents/core/Container";
import makeStyles from "../../components/muiComponents/core/makeStyles";

type Props = {
  routes: any;
};

export const DashBoard: FC<Props> = props => {
  const pathname = window.location.pathname
  const isRoot = lodash.isEqual(pathname, '/')
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [isFullScreen, setIsFullscreen] = useState<boolean>(false)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const redirectState = useSelector<RootState>((state) => {
    return state.redirect.model
  }) as IRedirect

  useEffect(() => {
    if (!lodash.isEmpty(redirectState?.path)) {
      navigate(redirectState.path)
      dispatch(redirectActions.postRedirectPath(null))
    }
  }, [redirectState])

  const isRootPage = () => {
    return isRoot
  }

  return (
    <React.Fragment>
      {
        (isRootPage()) && <Layout />
      }
      {
        (!isRootPage()) &&
        <div className={classes.root}>
          <AppBar position="static" color="secondary" style={{ padding: '3px' }}>
            <Grid container>
              <Grid item xs={3} md={3} xl={1}>
                <Tabs value={value} onChange={handleChange} indicatorColor="secondary">
                  <Tab label="Livescore" onClick={() => navigate('')} {...a11yProps(0)} />
                </Tabs>
              </Grid>
              <Grid item xs={9} md={9} xl={9} style={{ marginLeft: 'auto', marginRight: 0, display: 'flex', justifyContent: 'flex-end' }}>
                <Tabs value={value} indicatorColor="secondary">
                    <Tab label="V6.52" />
                </Tabs>
                <UiModeComponent />
                <SignalRStateComponent />
                 {
                     !isFullScreen &&
                     <IconButton aria-label='previous'
                                 onClick={() => { document.body.requestFullscreen(); setIsFullscreen(true) }}>
                       <FullscreenOutlined />
                     </IconButton>
                 }
                 {
                     isFullScreen &&
                     <IconButton aria-label='previous'
                                 onClick={() => { document.exitFullscreen(); setIsFullscreen(false) }}>
                       <FullscreenExitOutlined />
                     </IconButton>
                 }
              </Grid>
            </Grid>
          </AppBar>
          <NavigationComponent />
          <Container className='root-container' maxWidth="xl" style={{ paddingTop: '1% !Important', margin: "0px"}}>
            {props.routes}
          </Container>
        </div>
      }
    </React.Fragment>
  );
}


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(({ palette: {common: {black, white}, mode} }) => ({
  root: {
    flexGrow: 1,
    backgroundColor: mode === "dark" ? black : white,
  },
  pageTitle: {
    marginTop: '1%',
    marginLeft: '5%'
  }
}));